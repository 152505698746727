import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";
import { useState } from "react";


const Join = () => {
  const form = useRef();
   const [email,setEmail] =useState('');
   const emailChangedHandler =(event)=>{
    setEmail(event.target.value);
}


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vx83by4",
        "template_gr2fgm7",
        form.current,
        "ZbfBvXnYkcqhKYGTb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

      const contactUsData = {
        email:email,  
    }
    console.log(contactUsData);

    //setting data empty when form is submit
    setEmail('');
    e.target.reset();
     
  };

  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready to</span>
          <span>Level up</span>
        </div>
        <div>
          <span>your body</span>
          <span className="stroke-text">with us?</span>
        </div>
      </div>
      <div className="right-j">
        <form
          ref={form}
          action=""
          className="email-container"
          onSubmit={sendEmail}
        >
        
          <input
            type="email"
            name="user_email"
            placeholder="Enter your Email address here..."
            value={email}
            onChange={emailChangedHandler} />
        
          
          <button className="btn btn-j" style={{ color: "white" }}>
            Join Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Join;
