import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import LinkedIn from "../../assets/linkedin.png";



const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
        <img src={Github} alt="git-logo" className="git"/>
        <img src={Instagram} alt="insta-logo" className="insta" />
        <img src={LinkedIn} alt="link-logo" className="link"/>
      </div>
      <div className="logo-f">
      <span style={{color:'white'}}>Address: 7th floor WestPort, PanCard Road, Baner, Pune, India</span>
      <span style={{color:'white'}}>Contact No: +91 6724983733</span>
      <span style={{color:'white'}}>Email: fitclub0602@ishnik.com</span>
       <div> <img src='https://cdn-icons-png.flaticon.com/256/4669/4669575.png' alt="logo" style={{size:'5px' ,marginTop:"20px"}}/>
        <span style={{color:'white',marginTop:"20px"}} className="logo-n">FIT CLUB</span></div>
      </div>
      <div style={{marginTop:'-50px',color:'white',fontSize:'15px', fontFamily:'"Times New Roman", Times, serif'}} className="Copyright">Copyright ©2022 All rights reserved | Vinaykumar Gupta</div>
      </div>
      <div className="blur" style={{width:'26rem',height:'12rem',right:'15%', bottom:'0', filter:'blur(100px)',background:'red'}}></div>
      <div className="blur" style={{width:'26rem',height:'12rem',left:'15%', bottom:'0',filter:'blur(100px)',background:'var(--orange)'}}></div>
    </div>
  );
};

export default Footer;
