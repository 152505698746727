import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../assets/hero_image.png";
import hero_image_back from "../assets/hero_image_back.png";
import Heart from "../assets/heart.png";
import Calories from "../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";
import { Link } from "react-scroll";
// import {
//   Link
// } from "react-router-dom";

const Hero = () => {
  const value = true;
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero" id="hero">
      <div
        className="blur hero-blur"
        style={{ width: "22rem", height: "30rem", left: "0" }}
      ></div>
      <div className="left-h">
        <Header />

        {/*the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "150px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>The best fitness club in the baner</span>
        </div>

        {/*the hero heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>Ideal Body</div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>

        {/*Figures */}
        <div className="figures">
          <div>
            <span>
              + <NumberCounter end={140} start={100} delay="4" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
              + <NumberCounter end={978} start={100} delay="4" />
            </span>
            <span>Members joined</span>
          </div>
          <div>
            <span>
              +<NumberCounter end={50} start={10} delay="4" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>

        {/*Hero Buttons */}
        <div className="hero-buttons">
          <button className="btn">
            <Link
              to="plans"
              activeClass="active"
              span={value.toString()}
              smooth={true}
            >
              Get Started
            </Link>
          </button>
          <button className="btn">
            <Link
              to="reasons"
              activeClass="active"
              span={value.toString()}
              smooth={true}
            >
              Learn More
            </Link>
          </button>
        </div>
      </div>

      {/*Right side of hero section */}
      <div className="right-h">
        <button className="btn">Join Now</button>

        <motion.div
          className="heart-rate"
          transition={transition}
          whileInView={{ right: "4rem" }}
          initial={{ right: "-1rem" }}
        >
          <img src={Heart} alt="logo" />
          <span>Heart Rate</span>
          <span>
            <NumberCounter end={116} start={10} delay="4" /> bpm
          </span>
        </motion.div>

        {/*Hero images */}
        <img src={hero_image} alt="Hero" className="hero_image" />
        <motion.img
          src={hero_image_back}
          alt="Back"
          className="hero_image_back"
          transition={transition}
          whileInView={{ right: "20rem" }}
          initial={{ right: "11rem" }}
        />

        {/*calories */}
        <motion.div
          className="calories"
          transition={transition}
          whileInView={{ right: "28rem" }}
          initial={{ right: "37rem" }}
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories burned</span>
            <span>
              <NumberCounter end={220} start={100} delay="4" /> kcal
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
