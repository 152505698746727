
import './App.css';
import Footer from './Component/Footer/Footer';
import Hero from './Component/Hero';
import Join from './Component/Join/Join';
import Plans from './Component/Plans/Plans';
import Program from './Component/Program/Program';
import Reasons from './Component/Reasons/Reasons';
import Testimonials from './Component/Testimonials/Testimonials';
// import Trainers from './Component/Trainers/Trainers';
import { BrowserRouter as Router, Route } from 'react-router-dom'
// import JoinUsForm from './Component/JoinUsForm/JoinUsForm';

function App() {
  return (
    <Router>
      <div>
        
    <div className="App">
    <Route exact path="/">
       <Hero/>
       <Program/>
       <Reasons/>
       <Plans/>
       <Testimonials/>
       <Join/>
       <Footer/>
       {/* <Trainers/> */}
       </Route>
    </div>
    
      {/* <Route path="/JoinUsForm"> <JoinUsForm/></Route> */}
    </div>
    </Router>
  );
}

export default App;
