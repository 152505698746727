import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";

const Plans = () => {
  return (
    <div className="plans-container" id="plans">
      <div className="blur" style={{width:'32rem',height:'22rem',left:'0',top:'6rem'}}></div>
      <div className="blur" style={{width:'32rem',height:'22rem',right:'0',top:'10rem'}}></div>
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">Ready to Start</span>
        <span>Your Journey</span>
        <span className="stroke-text">now with us</span>
      </div>

      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span >{plan.name}</span>
            <div>
            <span>₹ {plan.price}</span>
            <span className="month" >/month</span>
            </div>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature" key={i}>
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>

            <div>
              <span>See more benefits -{'>'} </span>
            </div>
            <button className="btn" >Join Now </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
